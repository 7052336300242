@import '../../styles/customMediaQueries.css';

.pageTitle {
  text-align: center;
}

.mainWrapper {
  width: calc(100% - 48px);
  max-width: 720px;
  margin: 24px auto;
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

/* for label see /Accordion/Styles.css */

.accordion {
  margin: 30px 0 50px 0;
  width: 95%;
}

.cardContent {
  background-color: #fff;
  padding: 15px 25px;
}

.tab {
  composes: h3 fontWeightSemiBold from global;
  margin-top: 32px;
  margin-bottom: 16px;
}

/* increase Eng BSL tab */
.tab > div > button {
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
  padding: 10px 10px 10px 0;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  white-space: pre-wrap;
}

.additionalHelp {
  margin-top: 2rem;
}
